import {isXbox, isTizen} from '@fsa-streamotion/browser-utils';

import noop from 'lodash/noop';

import type PlaybackNative from '.';
import type {PlayerTechOptions, SupportedPlaybackHandler} from '../../types';
import {triggerCustomEvent} from '../utils';

export default class NativeBuffer {
    bufferingStartEvents = (() => {
        const events = new Set(['loadstart', 'seeking', 'stalled', 'waiting']);

        if (isXbox()) {
            events.delete('stalled'); // Xbox Series X consistently emits `stalled` event even without buffering
            events.delete('waiting'); // Xbox Series S occasionally emits `waiting` event even without buffering
        }

        return [...events];
    })();

    bufferingFinishEvents = [
        'seeked',
        'playing',
        'canplaythrough',
        'error',
        'ended',
    ];

    bufferingProgressEvents = ['progress'];

    videoElement: HTMLVideoElement;
    playbackTech: PlaybackNative;
    bufferingCallback: PlayerTechOptions['bufferingCallback'];
    bufferingPercentageCallback: PlayerTechOptions['bufferingPercentageCallback'];
    playbackHandler: SupportedPlaybackHandler;
    bufferingMonitorInterval: number;

    isBuffering = false;
    isSeeking = false;

    constructor(
        playbackTech: PlaybackNative,
        playbackHandler: SupportedPlaybackHandler
    ) {
        this.videoElement = playbackTech.videoElement as HTMLVideoElement;
        this.bufferingCallback = playbackTech.options.bufferingCallback || noop;
        this.bufferingPercentageCallback =
            playbackTech.options.bufferingPercentageCallback || noop;
        this.bufferingMonitorInterval =
            playbackTech.options.bufferingMonitorInterval || 1_000;
        this.playbackTech = playbackTech;
        this.playbackHandler = playbackHandler;

        this.bufferingStartEvents.forEach((eventName) => {
            this.videoElement.addEventListener(
                eventName,
                this.eventBufferStart
            );
        });

        this.bufferingFinishEvents.forEach((eventName) => {
            this.videoElement.addEventListener(
                eventName,
                this.eventBufferFinish
            );
        });

        this.bufferingProgressEvents.forEach((eventName) => {
            this.videoElement.addEventListener(
                eventName,
                this.eventBufferProgress
            );
        });
    }

    destroy(): void {
        this.bufferingStartEvents.forEach((eventName) => {
            this.videoElement.removeEventListener(
                eventName,
                this.eventBufferStart
            );
        });

        this.bufferingFinishEvents.forEach((eventName) => {
            this.videoElement.removeEventListener(
                eventName,
                this.eventBufferFinish
            );
        });

        this.bufferingProgressEvents.forEach((eventName) => {
            this.videoElement.removeEventListener(
                eventName,
                this.eventBufferProgress
            );
        });
    }

    // Please note, this method always returns false for Tizen and Xbox.
    // On Tizen, networkState is always NETWORK_IDLE when buffering
    // On Xbox, readyState is always HAVE_ENOUGH_DATA when buffering
    isVideoPlaybackStalled(): boolean {
        return (
            this.videoElement.networkState ===
                this.videoElement.NETWORK_LOADING &&
            this.videoElement.readyState < this.videoElement.HAVE_FUTURE_DATA
        );
    }

    // Allows trySetBufferStart() to be overridden by extending classes, while maintaining bound scope for add/remove event.
    eventBufferStart = (event: Event): boolean => this.trySetBufferStart(event);

    trySetBufferStart(event: Event): boolean {
        if (event.type === 'seeking') {
            this.isSeeking = true;
        }

        if (
            !this.isBuffering &&
            (isXbox() || isTizen() || this.isVideoPlaybackStalled())
        ) {
            this.isBuffering = true;
            this.triggerBufferingPercentage(0); // Because we are 'starting'.
            this.triggerBuffering(true);

            return true;
        }

        return false;
    }

    // Allows trySetBufferProgress() to be overridden by extending classes.
    eventBufferProgress = (): boolean => this.trySetBufferProgress();

    trySetBufferProgress = (): boolean => {
        if (this.isBuffering) {
            // If we're not actually stalled, go away.
            return true;
        } else {
            // Typically this is where we'd ask for details around percentage downloaded.
            // Native video elements don't seem to expose this level of detail though.
            return false;
        }
    };

    // Allows trySetBufferFinish() to be extended while bound.
    eventBufferFinish = (): boolean => this.trySetBufferFinished();

    trySetBufferFinished(): boolean {
        if (
            this.isBuffering &&
            (isXbox() || isTizen() || !this.isVideoPlaybackStalled())
        ) {
            this.isBuffering = false;
            this.isSeeking = false;
            this.triggerBufferingPercentage(100); // Because we are 'done'.
            this.triggerBuffering(false);

            return true;
        }

        return false;
    }

    triggerBuffering(isBuffering: boolean): void {
        this.bufferingCallback?.(isBuffering);
        triggerCustomEvent(this.videoElement, 'fs-stalled-buffering', {
            isBuffering,
            isSeeking: this.isSeeking,
        });
    }

    triggerBufferingPercentage(bufferPercentage: number): void {
        this.bufferingPercentageCallback?.(bufferPercentage); // Hit any custom callback
        triggerCustomEvent(
            this.videoElement,
            'fs-stalled-buffering-percentage',
            {bufferPercentage}
        );
    }
}
