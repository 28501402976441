import type RxPlayer from 'rx-player';

import type {AudioTrack} from '../..';
import NativeAudio from '../native/audio';
import {CODEC_MAP_TO_CHANNEL_COUNT, sortAudioTracksByCodec} from '../utils';

export default class RxPlayerAudio extends NativeAudio {
    declare playbackHandler: RxPlayer;

    override setup(): void {
        this.playbackHandler.addEventListener(
            'availableAudioTracksChange',
            this.initialiseTrackList
        );

        this.playbackHandler.addEventListener(
            'periodChange',
            this.setAudioTrackForNewPeriod
        );
    }

    override destroy(): void {
        this.playbackHandler.removeEventListener(
            'availableAudioTracksChange',
            this.initialiseTrackList
        );

        this.playbackHandler.removeEventListener(
            'periodChange',
            this.setAudioTrackForNewPeriod
        );
    }

    override get currentAudioTrackIndex(): number {
        return this.tracks.findIndex(
            (track) =>
                track.id?.toString() ===
                this.playbackHandler.getAudioTrack()?.id
        );
    }

    override set currentAudioTrackIndex(index: number) {
        const changeToTrack = this.tracks[index];

        if (changeToTrack && changeToTrack.id) {
            this.playbackTech.options.audioPreferences = {
                codec: changeToTrack.codec,
                language: changeToTrack.language,
            };

            this.playbackHandler.setAudioTrack({
                trackId: changeToTrack.id.toString(),
                switchingMode: changeToTrack.enabled ? 'seamless' : 'reload',
            });

            this.currentTrackIndex = index;

            this.tracks = (this.tracks as AudioTrack[]).map(
                (track, trackIndex) => ({
                    ...track,
                    enabled: trackIndex === index,
                })
            );

            this.dispatchAudioTracksUpdatedEvent();
        }
    }

    private setAudioTrackForNewPeriod = (): void => {
        this.initialiseTrackList();

        this.currentAudioTrackIndex = this.currentTrackIndex;
    };

    private initialiseTrackList = (): void => {
        if (!this.playbackHandler) {
            return;
        }

        const userAudioTrackPreference =
            this.playbackTech.options.audioPreferences;

        this.tracks = sortAudioTracksByCodec(
            this.playbackHandler.getAvailableAudioTracks().map((track) => {
                const codec = `audio/mp4;codecs="${
                    track.representations?.[0]?.codec || 'mp4a.40.2'
                }"`;
                const channelCount = CODEC_MAP_TO_CHANNEL_COUNT[codec] || 2;

                return {
                    id: track.id,
                    language: track.language || track.normalized,
                    label: track.label,
                    enabled: userAudioTrackPreference?.codec === codec,
                    kind: track.normalized,
                    channelCount,
                    codec,
                };
            })
        );

        const initialAudioTrackIndex = this.tracks.findIndex(
            (track) => track.enabled
        );

        this.currentAudioTrackIndex =
            initialAudioTrackIndex === -1 ? 0 : initialAudioTrackIndex;

        this.dispatchAudioTracksUpdatedEvent();
    };
}
