import cloneDeep from 'lodash/cloneDeep';
import type RxPlayer from 'rx-player';

import NativeCaptions from '../native/captions';

export default class RxPlayerCaptions extends NativeCaptions {
    declare playbackHandler: RxPlayer;

    override setup(): void {
        this.playbackHandler.addEventListener(
            'availableTextTracksChange',
            this.initialiseTrackList
        );

        this.playbackHandler.addEventListener(
            'periodChange',
            this.updateTextTrack
        );
    }

    override destroy(): void {
        this.playbackHandler.removeEventListener(
            'availableTextTracksChange',
            this.initialiseTrackList
        );

        this.playbackHandler.removeEventListener(
            'periodChange',
            this.updateTextTrack
        );

        super.destroy();
    }

    initialiseTrackList = (): void => {
        if (!this.playbackHandler) {
            return;
        }

        this.textTracksList = cloneDeep(
            this.playbackHandler.getAvailableTextTracks().map((track) => ({
                ...track,
                label: track.label || track.language,
            }))
        );

        this.textTrack = this.currentTextTrackIndex;

        this.dispatchCaptionsUpdatedEvent();
    };

    updateTextTrack = (): void => {
        this.initialiseTrackList();

        this.textTrack = this.currentTextTrackIndex;
    };

    override set textTrack(index: number) {
        const selectedTextTrack = this.textTracksList?.[index];

        if (selectedTextTrack && selectedTextTrack.id) {
            this.playbackHandler.setTextTrack({
                trackId: selectedTextTrack.id.toString(),
            });
            this.currentTextTrackIndex = index;
        } else {
            this.playbackHandler.disableTextTrack();
            this.currentTextTrackIndex = -1;
        }

        this.dispatchCaptionsUpdatedEvent();
    }

    override disableTextTrack(): void {
        this.textTrack = -1;
    }
}
