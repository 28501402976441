export default class HlsjsAdapter {
  checkExistsPlayer() {
    try {
      return this.checkExistsObjectOnPage(this.player.media);
    } catch (err) {
      return true;
    }
  }

  getVersion() {
    return '7.0.6-hlsjs-jsclass';
  }

  getPlayhead() {
    var ret = null;
    if (this.getVideo() && this.getVideo().isBreakStarted) {
      return this._lastPlayhead;
    }
    if (this.tag) {
      ret = this.tag.currentTime;
    }
    return ret;
  }

  getPlayrate() {
    var ret = null;
    if (this.tag) {
      ret = this.tag.playbackRate;
    }
    return ret;
  }

  getDroppedFrames() {
    var ret = null;
    if (this.tag) {
      ret = this.tag.webkitDroppedFrameCount;
    }
    return ret;
  }

  getDuration() {
    var ret = null;
    if (this.tag && !this.getIsLive()) {
      ret = this.tag.duration;
    }
    return ret;
  }

  getBitrate() {
    var ret = null;
    if (this.player.currentLevel !== -1 && this.player.levels[this.player.currentLevel]) {
      ret = this.player.levels[this.player.currentLevel].bitrate;
    }
    return ret;
  }

  getRendition() {
    var ret = null;
    if (this.player.currentLevel !== -1) {
      var level = this.player.levels[this.player.currentLevel];
      if (level) {
        if (level.name) {
          ret = level.name;
        } else {
          if (level.bitrate) {
            ret = this.getNpawUtils().buildRenditionString(level.width, level.height, level.bitrate);
          }
        }
      }
    }
    return ret;
  }

  getIsLive() {
    var ret = null;
    if (this.player.levels && this.player.levels[this.player.currentLevel]) {
      ret = this.player.levels[this.player.currentLevel].details.live;
    }
    return ret;
  }

  getLatency() {
    var latency = this.player.latency;
    return latency ? latency * 1000 : undefined;
  }

  getResource() {
    return this.player.url;
  }

  getPlayerVersion() {
    return (typeof Hls !== 'undefined') ? Hls.version : 'unknown';
  }

  getPlayerName() {
    return 'Hls.js';
  }

  registerListeners() {
    this._lastPlayhead = 0;
    if (!this.mediaFound) {
      this._registerHlsRef();
    } else {
      if (!this.hlsReference && !this.hlsMediaReference) {
        this.tag = this.player ? this.player.media : null;
        this._registerHlsRef();
      }
      this.references = {
        play: this.playListener.bind(this),
        pause: this.pauseListener.bind(this),
        playing: this.playingListener.bind(this),
        seeking: this.seekingListener.bind(this),
        error: this.errorListener.bind(this),
        ended: this.endedListener.bind(this),
        progress: this.progressListener.bind(this),
        waiting: this.bufferingListener.bind(this),
        timeupdate: this.timeupdateListener.bind(this)
      };
      this.player.on(Hls.Events.LEVEL_SWITCHED, this.detectQualityChange.bind(this));
      for (var key in this.references) {
        this.tag.addEventListener(key, this.references[key]);
      }
    }
  }

  detectQualityChange(event, data) {
    let ret;
    const level = this.player.levels[data.level];
    if (level) {
      if (level.name) {
        ret = level.name;
      } else {
        if (level.bitrate) {
          ret = this.getNpawUtils().buildRenditionString(level.width, level.height, level.bitrate);
        }
      }
    }
    if (ret) {
      this.storeNewRendition(ret);
    }
  }

  _registerHlsRef() {
    this.hlsReference = this.hlsErrorListener.bind(this);
    this.hlsMediaReference = this.getTagListener.bind(this);
    this.player.on('hlsError', this.hlsReference, this);
    this.player.on('hlsMediaAttached', this.hlsMediaReference);
  }

  unregisterListeners() {
    if (this.monitor) this.monitor.stop();
    if (this.player) {
      if (this.hlsReference) {
        this.player.off('hlsError', this.hlsReference, this);
      }
      if (this.hlsMediaReference) {
        this.player.off('hlsMediaAttached', this.hlsMediaReference);
      }
      this.player.off(Hls.Events.LEVEL_SWITCHED ,this.detectQualityChange.bind(this));
      this.hlsReference = null;
      this.hlsMediaReference = null;
    }
    if (this.tag && this.references) {
      for (var key in this.references) {
        this.tag.removeEventListener(key, this.references[key]);
      }
      delete this.references;
    }
  }

  progressListener(e) {
    this._lastPlayhead = this.getPlayhead();
    if (!this.flags.isStarted) {
      if (this.getPlayhead() > 1 && (this.startedOnce || !this.getVideo().getIsLive())) {
        this.fireStart({}, 'progressListener');
        this.fireJoin({}, 'progressListener');
      }
    } else if (!this.flags.isJoined && this.getVideo().getIsLive()) {
      this.fireJoin({}, 'progressListener');
    }
  }

  timeupdateListener(e) {
    this._lastPlayhead = this.getPlayhead();
    if (!this.flags.isStarted) {
      if (this.getPlayhead() > 1 && (this.startedOnce || !this.getVideo().getIsLive())) {
        this.fireStart({}, 'timeupdateListener');
        this.fireJoin({}, 'timeupdateListener');
      }
    } else if (!this.flags.isJoined && this.getVideo().getIsLive()) {
      this.fireJoin({}, 'timeupdateListener');
    }
  }

  playListener(e) {
    this.fireStart({}, 'playListener');
    this.startedOnce = true;
  }

  pauseListener(e) {
    if (!this.flags.isBuffering) {
      this.firePause({}, 'pauseListener');
      this.pauseStamp = e.timeStamp;
    }
  }

  playingListener(e) {
    this.fireJoin({}, 'playingListener');
    this.fireBufferEnd({}, 'playingListener');
    this.fireSeekEnd({}, 'playingListener');
    this.fireResume({}, 'playingListener');
  }

  seekingListener(e) {
    this.fireSeekBegin({}, false, 'seekingListener');
  }

  bufferingListener(e) {
    if (!this.tag || this.tag.readyState === 2) {
      this.fireBufferBegin({}, false, 'bufferingListener');
    }
  }

  errorListener(e) {
    this.fireError(this.tag.error.code, 'PLAY FAILURE', undefined, undefined, 'errorListener');
  }

  endedListener(e) {
    const adsAdapter = this.getVideo().getAdsAdapter();
    let willShowCSAIAds = false;
    if (adsAdapter && typeof adsAdapter.isDAI !== 'undefined') {
      if (!adsAdapter.isDAI) {
        // This means we are using Google IMA with CSAI
        willShowCSAIAds = adsAdapter.player.getCuePoints().includes(-1);
      }
    }
    if (!willShowCSAIAds) {
      this.fireStop({}, 'endedListener');
    }
  }

  hlsErrorListener(event, error) {
    if (error.fatal) {
      this.fireFatalError(error.details, 'PLAYER FAILURE', undefined, undefined, 'hlsErrorListener');
    }
  }

  getTagListener() {
    this.tag = this.player ? this.player.media : null;
    this.mediaFound = true;
    this.registerListeners();
  }
}
