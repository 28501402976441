import {loadScript} from '@fsa-streamotion/browser-utils';

import first from 'lodash/first';
import property from 'lodash/property';
import sortBy from 'lodash/sortBy';

import type {
    SourceConfig,
    SupportedDashPlaybackHandler,
    SupportedPlayerTech,
} from '../types';
import PlaybackDash from './dash';
import PlaybackHls from './hls';
import PlaybackNative from './native';
import PlaybackNativeHisenseModified from './native/hisense-modified';
import PlaybackRx from './rx-player';
import type {PlaybackHandlerArgs} from './types';

export const CODEC_MAP_TO_CHANNEL_COUNT: Record<string, number> = {
    'audio/mp4;codecs="mp4a.40.2"': 2,
    'audio/mp4;codecs="ac-3"': 6,
    'audio/mp4;codecs="ec-3"': 8,
};

// Always provide the tracks in a consistent order based on codec
const CODECS_ORDER = [
    'audio/mp4;codecs="mp4a.40.2"', // Stereo
    'audio/mp4;codecs="ac-3"', // Dolby Digital 5.1'
    'audio/mp4;codecs="ec-3"', // Dolby Digital Plus'
];

export function sortAudioTracksByCodec<
    T extends {codec: string | null | undefined}
>(tracks: T[]): T[] {
    return sortBy<T>(tracks, ({codec}) => CODECS_ORDER.indexOf(codec ?? ''));
}

type ReturnTypes = Pick<PlaybackHandlerArgs, 'availableKeySystems'> &
    Pick<
        SourceConfig,
        | 'src'
        | 'type'
        | 'cdnProvider'
        | 'withCredentials'
        | 'hasSsai'
        | 'keySystems'
    > & {
        PlaybackHandler: SupportedPlayerTech;
        couldPlay: CanPlayTypeResult;
    };

export async function getBestPlaybackHandlerWithSource({
    sources = [],
    videoElement = null,

    // Order of preference for playback handler.
    playbackHandlers = [
        // PlaybackWebmaf,
        PlaybackDash,
        PlaybackRx,
        PlaybackHls,

        // Dear brave soul.  Hisense native playback bugs from 2020.
        // When hisense resolve video elements reloading their source after 'ended' event is fired, we can remove
        // this check, and NativePlaybackHisense override.

        // Make another test around #t= for startAt which is accounted there, and can be moved back to this
        // class's setup method if we want.
        PlaybackNativeHisenseModified,

        PlaybackNative,
    ],
    preferredDashPlaybackHandler = 'dashjs',
}: {
    sources: SourceConfig[];
    videoElement: HTMLVideoElement | null;
    playbackHandlers?: SupportedPlayerTech[];
    preferredDashPlaybackHandler?: SupportedDashPlaybackHandler;
}): Promise<ReturnTypes | undefined> {
    const compatiblePlaybackAndSources = (
        await Promise.all(
            playbackHandlers.reduce<Promise<ReturnTypes>[]>(
                (acc, PlaybackHandler) => [
                    ...acc,
                    ...sources.map(
                        ({
                            src,
                            type,
                            cdnProvider,
                            hasSsai,
                            withCredentials,
                            keySystems,
                        }) =>
                            PlaybackHandler.canPlaySource({
                                src,
                                type,
                                videoElement,
                                keySystems,
                            }).then(async (couldPlay) => ({
                                availableKeySystems:
                                    await PlaybackHandler.getAvailableKeySystems(
                                        keySystems
                                    ),
                                cdnProvider,
                                couldPlay,
                                keySystems,
                                PlaybackHandler,
                                src,
                                hasSsai,
                                type,
                                withCredentials,
                            }))
                    ),
                ],
                []
            )
        )
    ).filter(property('couldPlay'));

    const couldPlayStrings = ['probably', 'maybe', ''];

    const sortedPlaybackAndSources = compatiblePlaybackAndSources
        .filter(({PlaybackHandler}) => {
            if (PlaybackHandler === PlaybackDash) {
                return preferredDashPlaybackHandler === 'dashjs';
            }

            if (PlaybackHandler === PlaybackRx) {
                return preferredDashPlaybackHandler === 'rxplayer';
            }

            return true;
        })
        .sort(
            (
                {
                    couldPlay: couldPlayA,
                    availableKeySystems: availableKeySystemsA = [],
                },
                {
                    couldPlay: couldPlayB,
                    availableKeySystems: availableKeySystemsB = [],
                }
            ) => {
                if (couldPlayA === couldPlayB) {
                    // If we have the same couldPlay choose the one who has more DRM key system options
                    return (
                        availableKeySystemsB.length -
                        availableKeySystemsA.length
                    );
                } else if (
                    couldPlayStrings.includes(couldPlayA) &&
                    couldPlayStrings.includes(couldPlayB)
                ) {
                    // Otherwise, choose the one with the best couldPlay
                    return (
                        couldPlayStrings.indexOf(couldPlayA) -
                        couldPlayStrings.indexOf(couldPlayB)
                    );
                } else {
                    return 0;
                }
            }
        );

    return first(sortedPlaybackAndSources);
}

export function triggerCustomEvent(
    element: HTMLElement | null,
    eventName: string,
    detail?: unknown
): void {
    try {
        const customEvent = new CustomEvent(eventName, {detail});

        element?.dispatchEvent(customEvent);
    } catch (e) {
        // Probably IE10 that doesn't support custom events. No bother to us.
    }
}

export async function loadLibrary<T>({
    src,
    integrity,
    loadedLibrary,
    id,
}: {
    src: string;
    integrity: string;
    loadedLibrary: T;
    id: string;
}): Promise<void> {
    // eslint-disable-line import/prefer-default-export
    if (!loadedLibrary) {
        await loadScript({
            url: src,
            integrity,
            id,
            crossOrigin: 'anonymous',
        });
    }
}
