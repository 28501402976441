import {
    isMsEdgeChromium,
    isWindows,
    isLg,
    isPs4,
    isPs5,
    isTizen,
    isHisense,
    isXbox,
} from '@fsa-streamotion/browser-utils';

import type {MediaInfo, MediaPlayerClass} from 'dashjs';

import type PlaybackDash from '.';
import NativeAudio from '../native/audio';
import {CODEC_MAP_TO_CHANNEL_COUNT, sortAudioTracksByCodec} from '../utils';
import type {DashAudioTrack} from './types';

export default class DashAudio extends NativeAudio {
    override tracks: DashAudioTrack[] = [];
    override currentTrackIndex = -1;
    declare playbackHandler: MediaPlayerClass;
    declare playbackTech: PlaybackDash;

    private preferredAudioTrack: DashAudioTrack | undefined;

    constructor({
        playbackTech,
        playbackHandler,
    }: {
        playbackTech: PlaybackDash;
        playbackHandler: MediaPlayerClass;
    }) {
        super(playbackTech, playbackHandler);
    }

    override setup(): void {
        this.playbackHandler.on(
            window.dashjs.MediaPlayer.events.PLAYBACK_METADATA_LOADED,
            this.initialiseTrackList,
            this
        );

        // Because each period is technically a different stream, we'll need to check we have the correct track set
        this.playbackHandler.on(
            window.dashjs.MediaPlayer.events.PERIOD_SWITCH_COMPLETED,
            this.onPeriodSwitch,
            this
        );
    }

    override destroy(): void {
        this.playbackHandler.off(
            window.dashjs.MediaPlayer.events.PLAYBACK_METADATA_LOADED,
            this.initialiseTrackList,
            this
        );
        this.playbackHandler.off(
            window.dashjs.MediaPlayer.events.PERIOD_SWITCH_COMPLETED,
            this.onPeriodSwitch,
            this
        );
        this.playbackHandler.off(
            window.dashjs.MediaPlayer.events.STREAM_INITIALIZED,
            this.setAudioTrackFromPreferredTrack,
            this
        );
    }

    setAudioTrackFromPreferredTrack(): void {
        // refresh the track list based on the current stream/period
        this.initialiseTrackList();

        // Looks like there’s an issue on Ad Tier where dash will change the audio tracks when jumping in and out of ad breaks.
        // The issue comes from the fact that all audio tracks in ad breaks are technically stereo (2 channels) even though they
        // still have AC-3(Dolby Digital 5.1), EC-3(Dolby Digital+) & AACL(Stereo) codecs. When switching periods, dash selects
        // the new audio track to match the number of channels it is currently playing without any regard for the codec,
        // so when returning from an ad break it will always switch back to AACL because it’s the only one with 2 channels
        // (AC-3 and EC-3 would typically have 6 channels). To deal with this, we’re going to have to force the correct audio
        // track every time we switch periods.
        this.playbackHandler.off(
            window.dashjs.MediaPlayer.events.STREAM_INITIALIZED,
            this.setAudioTrackFromPreferredTrack,
            this
        );
    }

    onPeriodSwitch(): void {
        if (this.preferredAudioTrack) {
            // Remove listener before adding a new one because we might get multiple period switches before we get a STREAM_INITIALIZED
            this.playbackHandler.off(
                window.dashjs.MediaPlayer.events.STREAM_INITIALIZED,
                this.setAudioTrackFromPreferredTrack,
                this
            );

            // Wait until we get a STREAM_INITIALIZED event for the new Period before attempting to set the track/reset the stream or else Dash gets upset
            this.playbackHandler.on(
                window.dashjs.MediaPlayer.events.STREAM_INITIALIZED,
                this.setAudioTrackFromPreferredTrack,
                this
            );
        }
    }

    /**
     * Handler for Dash's PLAYBACK_METADATA_LOADED event, so if the metadata is available then initialise the audio tracks
     *
     */
    initialiseTrackList(): void {
        // Always provide the tracks in a consistent order based on codec
        const newTracksList = sortAudioTracksByCodec(
            this.playbackHandler.getTracksFor('audio')
        );

        if (this.currentTrackIndex === -1 && newTracksList.length) {
            this.currentTrackIndex = 0;
        }

        const isWindowsEdge = isMsEdgeChromium() && isWindows();

        const filteredTracks = isWindowsEdge
            ? newTracksList.filter((audioTrack) =>
                  parseInt(audioTrack.audioChannelConfiguration?.[0])
              )
            : newTracksList;

        /**
         * Added filter to handle a potential issue in Dash Windows Edge
         * where even though it does not support EAC3 and AC3 audio tracks
         * it is attaching them to the video element with audioConfigurationChannel = null,
         * causing Player skin to display 3 stereo options. Dash typically uses MSE.isTypeSupported
         * to determine browser support for a particular track so this probably doesn't work on Edge
         */
        this.tracks = filteredTracks.map(
            ({
                audioChannelConfiguration,
                codec,
                index,
                labels,
                lang,
                roles,
            }) => {
                const channelCount = parseInt(audioChannelConfiguration?.[0]);

                return {
                    codec,
                    channelCount:
                        isNaN(channelCount) && codec
                            ? CODEC_MAP_TO_CHANNEL_COUNT[codec]
                            : channelCount,
                    enabled:
                        index ===
                        this.playbackHandler.getCurrentTrackFor('audio')?.index,
                    id: index,
                    kind: roles?.[0] || '',
                    label: labels[0] || `stream_${index}`,
                    language: lang,
                    audioChannelConfiguration:
                        audioChannelConfiguration as string[], // dash doesn't always give us an int e.g. F801 is used to describe 5.1
                };
            }
        );

        this.currentTrackIndex = this.tracks.findIndex(({enabled}) => enabled);

        // If we don't have a preferredAudioTrack, let's set it to the default track so we can maintain the same audio settings for the entire session (unless changed by the user)
        if (!this.preferredAudioTrack) {
            this.preferredAudioTrack = this.tracks[this.currentTrackIndex];
        }

        this.dispatchAudioTracksUpdatedEvent();
    }

    /**
     * Sets the active audio track's index
     *
     * @param index - The index of the audio track
     */
    override set currentAudioTrackIndex(index: number) {
        // Keep our tracks up to date when set new audio track
        this.initialiseTrackList();

        if (!this.tracks[index]) {
            return;
        }

        const newTrack = this.playbackHandler
            .getTracksFor('audio')
            .find((track) => track.index === this.tracks[index]?.id);
        const currentTrack = this.playbackHandler.getCurrentTrackFor('audio');

        // No need to set the track if it's already active
        if (
            !newTrack ||
            !currentTrack ||
            newTrack.index === currentTrack.index
        ) {
            return;
        }

        const {audioChannelConfiguration, codec, lang: language} = newTrack;

        this.playbackTech.options.audioPreferences = {
            audioChannelConfiguration: audioChannelConfiguration as string[],
            codec,
            language,
        };

        // Switch tracks immediately if the device supports it
        if (this.#canSeamlesslySwitchTrack(newTrack, currentTrack)) {
            this.playbackTech.setCustomInitialTrackSelectionFunction();
            this.playbackHandler.setCurrentTrack(newTrack);
            this.currentTrackIndex = index;
            this.preferredAudioTrack = this.tracks[index]; // Update the preferred track because it's been changed by the user.

            this.initialiseTrackList(); // Refresh the active track info.

            this.dispatchAudioTracksUpdatedEvent();

            return;
        }

        this.playbackTech.resetAndRestore();
    }

    #canSeamlesslySwitchTrack(
        currentTrack: MediaInfo,
        newTrack: MediaInfo
    ): boolean {
        if (currentTrack.codec === newTrack.codec) {
            return true;
        }

        // eslint-disable-next-line no-warning-comments
        // TODO: Use the following codes instead of hardcoded false when dash.js@4.7.2 get released, see also https://github.com/Dash-Industry-Forum/dash.js/pull/4221.
        // const compatibleCodecs = ['audio/mp4;codecs="ac-3"', 'audio/mp4;codecs="ec-3"'];

        // return [currentTrack, newTrack].every((track) => compatibleCodecs.includes(track.codec));

        return (
            !isPs4() &&
            !isPs5() &&
            !isLg() &&
            !isHisense() &&
            !isXbox() &&
            !isTizen()
        );
    }
}
