import {
    enterFullscreen,
    exitFullscreen,
    isElementFullscreen,
    isSafari,
    supportsAirPlay,
    setLocalStorageValue,
    getLocalStorageValue,
} from '@fsa-streamotion/browser-utils';
import {asBool, asNumber} from '@fsa-streamotion/transformers';

import find from 'lodash/find';
import get from 'lodash/get';
import result from 'lodash/result';

import type {
    AudioTrack,
    BaseBitrateInfo,
    BreakToRemove,
    PlayerQualityLevel,
} from '../../types';
import {
    getIsInPopOutMode,
    getShouldUseTizenAvplayer,
    setIsInPopOutMode,
    supportsPopOutPlayer,
} from '../../utils/browser';
import type {PlayerQuality} from '../../utils/quality-settings';
import {VOLUME, IS_MUTED} from '../../utils/storage-keys';
import type HlsBufferEdge from '../hls/buffer--edge';
import type RxPlayerEme from '../rx-player/eme';
import type {
    BasicMediaKeySystem,
    BufferRange,
    CanPlaySourceParams,
    KeySystemsOption,
    PlaybackHandlerArgs,
    TextTracks,
} from '../types';
import NativeAudio from './audio';
import NativeBuffer from './buffer';
import NativeCaptions from './captions';
import NativeCustomBitrate from './custom-bitrate';
import NativeDiagnostics from './diagnostics';
import NativeEme from './eme';
import NativeError from './error';
import NativeLivestream from './livestream';

const KNOWN_EXTENSION_TYPES = [
    // File source ends with file extension, or there's a `?` right after the extension.
    {extensionRegex: /\.m3u8($|\?|;)/i, extensionType: 'application/x-mpegURL'},
    {extensionRegex: /\.mpd($|\?|;)/i, extensionType: 'application/dash+xml'},
    {extensionRegex: /\.mp4($|\?|;)/i, extensionType: 'video/mp4'},
];

class PlaybackNative {
    static async canPlaySource({
        src,
        type,
        videoElement,
        keySystems,
    }: CanPlaySourceParams): Promise<CanPlayTypeResult> {
        // by saying that we can't play source here, we ensure this playback handler is not
        // used as a fallback for devices that have poor/no support for the underlying tech
        // this handler uses
        if (getShouldUseTizenAvplayer()) {
            return '';
        }

        const testElement =
            videoElement || (document ? document.createElement('video') : null);
        const testExtension = find(KNOWN_EXTENSION_TYPES, ({extensionRegex}) =>
            extensionRegex.test(src)
        );

        const testExtensionType = type || get(testExtension, 'extensionType');

        let couldPossiblyPlay: CanPlayTypeResult = 'maybe';

        // If we skip this block, we don't have an element or type to test against, so we don't really know.
        // Maybe we can. Maybe we can't.
        if (testElement && testExtensionType) {
            couldPossiblyPlay = testElement.canPlayType(testExtensionType); // We have a video element and a type. Lets see if we ask the DOM.
        }

        if (couldPossiblyPlay && keySystems) {
            // We need some DRM action here too, so check if we can handle that
            const supportsAnyDrm =
                (await PlaybackNative.getAvailableKeySystems(keySystems))
                    .length > 0;

            // We actually have a lot of confidence here whether or not we can play this source, so choose probably or none
            return supportsAnyDrm ? 'probably' : '';
        }

        return couldPossiblyPlay;
    }

    static async getAvailableKeySystems(
        keySystems: KeySystemsOption = {}
    ): Promise<PlaybackHandlerArgs['availableKeySystems']> {
        return Object.entries(keySystems).reduce<BasicMediaKeySystem[]>(
            (curr, [keySystemName, currentKeySystem]) =>
                [
                    currentKeySystem?.videoContentType,
                    currentKeySystem?.audioContentType,
                ]
                    .filter(Boolean)
                    .every(
                        (contentType) =>
                            contentType &&
                            window?.WebKitMediaKeys?.isTypeSupported?.(
                                keySystemName,
                                contentType
                            )
                    )
                    ? [
                          ...curr,
                          {keySystem: keySystemName} as BasicMediaKeySystem,
                      ]
                    : curr,
            // Object structure matches https://developer.mozilla.org/en-US/docs/Web/API/MediaKeySystemAccess
            []
        );
    }

    controllers = [];

    #hasCurrentTimeSetToEdgeForLive = false;

    availableKeySystems: PlaybackHandlerArgs['availableKeySystems'];
    options: PlaybackHandlerArgs['options'];
    videoElement: HTMLVideoElement | null;
    src: string | null;
    srcType: string | null;
    onError: PlaybackHandlerArgs['onError'];
    keySystems: PlaybackHandlerArgs['keySystems'];
    cdnProvider: PlaybackHandlerArgs['cdnProvider'];
    hasSsai: PlaybackHandlerArgs['hasSsai'];
    disableAutoVolume: PlaybackHandlerArgs['options']['disableAutoVolume'];

    controllerAudioInstance: NativeAudio | null = null;
    controllerBufferInstance: NativeBuffer | null | HlsBufferEdge = null;
    controllerCaptionsInstance: NativeCaptions | null = null;
    controllerCustomBitrateInstance: NativeCustomBitrate | null = null;
    controllerLivestreamInstance: NativeLivestream | null = null;
    controllerError: NativeError | null = null;
    controllerDiagnostics: NativeDiagnostics | null = null;
    controllerEmeInstance:
        | NativeEme
        | RxPlayerEme
        | null
        | {activeKeySystemName: string; destroy: () => Promise<void>} = null;

    /**
     * Creates a new Native playback handler
     *
     * @param videoElement - The videoElement the playerTech is attached to
     * @param src - The src we're trying to play
     * @param srcType - TODO Document me!
     * @param options - TODO Document me!
     * @param onError - TODO Document me!
     * @param availableKeySystems - TODO Document me!
     * @param keySystems - TODO Document me!
     * @param cdnProvider - TODO Document me!
     * @param hasSsai - TODO Document me!
     */
    constructor({
        videoElement,
        src,
        srcType,
        options = {startAt: 0},
        onError,
        availableKeySystems,
        keySystems,
        cdnProvider,
        hasSsai,
    }: PlaybackHandlerArgs) {
        this.videoElement = videoElement;
        this.src = src;
        this.srcType = srcType;
        this.options = {
            ...options,
            volume: asNumber(
                options.volume,
                asNumber(videoElement.volume, 1, 'PlayerTech'),
                'PlayerTech'
            ), // prefer a supplied volume otherwise take from videoElement
            muted: asBool(
                options.muted,
                asBool(videoElement.muted, false, 'PlayerTech'),
                'PlayerTech'
            ), // prefer a supplied muted otherwise take from videoElement
            disableAutoVolume: asBool(
                options.disableAutoVolume,
                false,
                'PlayerTech'
            ),
        };
        this.onError = onError;
        this.availableKeySystems = availableKeySystems;
        this.keySystems = keySystems;
        this.cdnProvider = cdnProvider;
        this.hasSsai = hasSsai;
        this.disableAutoVolume = this.options.disableAutoVolume;

        let volumeSettings: {
            volume?: number;
            muted?: boolean;
        };

        if (this.disableAutoVolume) {
            volumeSettings = {
                volume: this.options.volume,
                muted: this.options.muted,
            };
        } else {
            volumeSettings = {
                volume: getLocalStorageValue({
                    key: VOLUME,
                    defaultValue: this.options.volume,
                }) as number,
                muted:
                    this.options.muted ||
                    (getLocalStorageValue({
                        key: IS_MUTED,
                        defaultValue: this.options.muted,
                    }) as boolean),
            };
        }

        // Set the volume/mute on the video element in the next tick
        // This is a dirty hack required because we're using two buses to deal with player interactions
        // This is a temporary fix for alpha, but dazz has promised to refactor things so we can revert this to normal
        setTimeout(() => {
            Object.assign(videoElement, volumeSettings);
        });

        videoElement.addEventListener('volumechange', this.handleVolumeChange);
    }

    /**
     * Called when we're asked to 'setup' ourselves on a video element.
     *
     * @returns Did the setup complete successfully
     */
    setup(): Promise<boolean> {
        return new Promise((resolve) => {
            if (!this.videoElement || !this.src) {
                resolve(false);

                return;
            }

            let startAtModifier = '';

            // Adding `#t=` is a magic undocumented HTML Video thing.
            // Takes start and end times. So you can do `#t=5,600` for example.
            // We don't want the 'end' at though.
            // This doesn't cause the video to do anything more than start and stop at these
            // times. You can continue to play after an end of 10 for example.
            switch (this.options.startAt) {
                default:
                    startAtModifier = `#t=${this.options.startAt}`;
                    break;

                case 0:
                    startAtModifier = '#t=1';
                    break;

                // We expect no changes against a `-1` number.
                // Will leave live hls be edge, and 0 for all vods or event replays.
                case -1:
                    break;
            }

            this.controllerAudioInstance = new NativeAudio(this, null);
            this.controllerAudioInstance.setup();

            this.controllerBufferInstance = new NativeBuffer(this, null);
            this.controllerCaptionsInstance = new NativeCaptions(this, null);
            this.controllerCaptionsInstance.setupPiPListeners();
            this.controllerCaptionsInstance.setup();

            this.controllerCustomBitrateInstance = new NativeCustomBitrate(
                this,
                null
            );
            this.controllerLivestreamInstance = new NativeLivestream(
                this,
                null
            );
            this.controllerError = new NativeError(this, null);
            this.controllerDiagnostics = new NativeDiagnostics(
                this.videoElement,
                this.src,
                this.cdnProvider,
                this.hasSsai
            );

            this.videoElement.preload = 'none'; // don't preload when we update the source until player tech tells us to do so.
            this.videoElement.setAttribute('preload', 'none');
            this.videoElement.src = this.src + startAtModifier;

            if (this.options.withCredentials) {
                this.videoElement.setAttribute('withcredentials', 'true');
                this.videoElement.setAttribute(
                    'crossorigin',
                    'use-credentials'
                );
            }

            // If we have drm instructions, we'll need an EME Handler
            if (get(this.availableKeySystems, 'length')) {
                this.controllerEmeInstance = new NativeEme({
                    videoElement: this.videoElement,
                    src: this.src,
                    options: this.options,

                    keySystems: this.keySystems,
                    availableKeySystems: this.availableKeySystems,
                    onError: this.onError,
                });
            }

            resolve(true);
        });
    }

    /**
     * Remove the interval from video by given interval info
     *
     * @param breakToRemove - interval to remove
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async removeBreak(breakToRemove: BreakToRemove): Promise<void> {
        console.warn(
            'RemoveBreak Not supported in the native playback handler. Implement in subclasses.',
            breakToRemove
        );
    }

    /**
     * Called when we're asked to take ourselves off a video element.
     * By default we just clear out our own knowledge of things.
     */
    async destroy(): Promise<void> {
        /* eslint-disable no-unused-expressions */
        this.controllerAudioInstance && this.controllerAudioInstance.destroy();
        this.controllerBufferInstance &&
            this.controllerBufferInstance.destroy();
        this.controllerCaptionsInstance &&
            this.controllerCaptionsInstance.destroy();
        this.controllerCustomBitrateInstance &&
            this.controllerCustomBitrateInstance.destroy();
        this.controllerLivestreamInstance &&
            this.controllerLivestreamInstance.destroy();
        this.controllerError && this.controllerError.destroy();
        this.controllerDiagnostics && this.controllerDiagnostics.destroy();
        /* eslint-enable no-unused-expressions */

        if (this.videoElement) {
            this.videoElement.removeAttribute('withcredentials');
            this.videoElement.removeAttribute('crossorigin');
            this.videoElement.removeAttribute('preload');
            this.videoElement.preload = '';

            this.videoElement.removeEventListener(
                'volumechange',
                this.handleVolumeChange
            );
        }

        // eslint-disable-next-line no-unused-expressions
        this.controllerEmeInstance &&
            (await this.controllerEmeInstance.destroy());

        this.videoElement = null;
        this.src = null;
        this.srcType = null;

        this.#hasCurrentTimeSetToEdgeForLive = false;
    }

    /**
     * Requests the current media preload Metadata.
     */
    preloadMetadata(): void {
        if (this.videoElement) {
            this.videoElement.preload = 'metadata';
            this.videoElement.setAttribute('preload', 'metadata');
        }
    }

    preloadAuto(): void {
        if (this.videoElement) {
            this.videoElement.preload = 'auto';
            this.videoElement.setAttribute('preload', 'auto');
        }
    }

    /*
    Playback Functions
     */

    /**
     * With the current tech, hit the play button!
     */
    play(): void {
        this.videoElement
            ?.play()
            ?.then(() => {
                // This is to fix live-linear assets assets in safari not playing from edge in some cases due to video current time not getting set correctly
                // We can't handle this race condition in livestream.js on onEventPlay method
                // due to video current time and video seekable won't be available correctly and we can't call setCurrentTimeToEdge method
                if (
                    isSafari() &&
                    !this.#hasCurrentTimeSetToEdgeForLive &&
                    !this.controllerLivestreamInstance?.onEdge &&
                    this.controllerLivestreamInstance?.live &&
                    this.options.startAt === -1
                ) {
                    this.#hasCurrentTimeSetToEdgeForLive = true;
                    this.setCurrentTimeToEdge();
                }
            })
            ?.catch((exception) => {
                // We've been rejected from playing. Chances are it's a DOM Exception with autoplay no human interaction.
                const exceptionString = result(
                    exception,
                    'toString',
                    'unknown'
                );

                if (
                    exceptionString.includes('NotAllowedError') && // Chrome reports: "NotAllowedError: play() failed because the user didn't interact with the document first."
                    exceptionString.includes("user didn't interact")
                    // @TODO Add more browsers maybe? No idea. I'm having troubles getting anything else to throw.
                ) {
                    console.info('Play refused, attempting play muted.'); // eslint-disable-line no-console
                    this.muted = true;

                    this.videoElement
                        ?.play()
                        .catch(
                            (exception) =>
                                void console.error(
                                    'Unable to play muted.',
                                    exception
                                )
                        );
                } else {
                    console.error('Unable to get autoplay.', exceptionString);
                }
            });
    }

    pause(): void {
        this.videoElement?.pause();
    }

    async resetAndRestore(): Promise<void> {
        // 1. extract info so we can restore later
        const {videoElement, src, srcType} = this;

        this.options.startAt = this.currentTime ?? this.options.startAt;
        this.options.autoPlay = true;

        // 2. destroying to reset
        await this.destroy();

        // 3. restoring
        Object.assign(this, {videoElement, src, srcType});
        await this.setup();

        if (this.options.autoPlay) {
            this.play();
        } else {
            this.preloadAuto();
        }
    }

    get duration(): number {
        if (!this.videoElement) {
            return Infinity;
        }

        if (isFinite(this.videoElement.duration)) {
            // VOD
            return this.videoElement.duration;
        } else {
            // Live Streams don't 'technically' have a duration. They're considered infinite.
            // So for all our playback tech where we see live, we attempt to normalise this behaviour between how HTML5 would
            // do it, vs how all things like Dashjs and HLS.js would.
            // In the event we really can't work it out (cause short DVR streams for example do this),
            // we just continue on that this in an Infinte stream.
            const isSeekable = this.videoElement.seekable.length > 0;
            const seekableDurationSeconds =
                isSeekable &&
                this.videoElement.seekable.end(
                    this.videoElement.seekable.length - 1
                );

            return seekableDurationSeconds || Infinity;
        }
    }

    get currentTime(): number {
        return this.videoElement?.currentTime ?? NaN;
    }

    set currentTime(newCurrentTime) {
        if (!this.videoElement) {
            return;
        }

        if (newCurrentTime >= this.duration) {
            // This check here is because if you seek to the end of a video,
            // a video will actually start replaying.
            this.videoElement.currentTime = this.duration - 0.1;
        } else {
            this.videoElement.currentTime = newCurrentTime;
        }
    }

    get volume(): number {
        return this.videoElement?.volume ?? NaN;
    }

    set volume(newVolume) {
        if (!this.videoElement) {
            return;
        }

        this.videoElement.volume = newVolume;
    }

    get muted(): boolean {
        return this.videoElement?.muted ?? false;
    }

    set muted(shouldMute) {
        if (!this.videoElement) {
            return;
        }

        this.videoElement.muted = shouldMute;
    }

    toggleMute(): void {
        if (!this.videoElement) {
            return;
        }

        this.videoElement.muted = !this.videoElement.muted;
    }

    saveVolume(): void {
        if (!this.videoElement) {
            return;
        }

        setLocalStorageValue({
            key: VOLUME,
            value: this.videoElement.volume,
        });

        setLocalStorageValue({
            key: IS_MUTED,
            value: this.videoElement.muted,
        });
    }

    /**
     * When HTML5Video fires a volumechange event, save the volume level and mute state to localstorage as our new defaults
     * Note that both changing volume or toggling mute will fire this same event
     */
    handleVolumeChange = (): void => {
        // Save volume and muted unless auto-volume is disabled
        if (!this.disableAutoVolume) {
            this.saveVolume();
        }
    };

    get poster(): string | null {
        if (!this.videoElement) {
            return null;
        }

        return this.videoElement?.getAttribute('poster');
    }

    set poster(newPoster: string | null) {
        if (!this.videoElement) {
            return;
        }

        if (newPoster) {
            this.videoElement.setAttribute('poster', newPoster);
        } else {
            this.videoElement.removeAttribute('poster');
        }
    }

    get playbackRate(): number {
        return this.videoElement?.playbackRate || 1;
    }

    set playbackRate(newRate) {
        if (!this.videoElement) {
            return;
        }

        this.videoElement.playbackRate = newRate;
    }

    checkElementIsFullscreen(fullscreenElement: Element): boolean {
        return isElementFullscreen(fullscreenElement);
    }

    enterFullscreen(
        newFullscreenElement: Element | undefined | null = undefined
    ): void {
        if (!this.videoElement) {
            return;
        }

        enterFullscreen({
            videoElement: this.videoElement,
            fullscreenElement: newFullscreenElement,
        });
    }

    exitFullscreen(): void {
        if (!this.videoElement) {
            return;
        }

        exitFullscreen(this.videoElement);
    }

    toggleFullscreen(fullscreenElement: Element): void {
        if (this.checkElementIsFullscreen(fullscreenElement)) {
            this.exitFullscreen();
        } else {
            this.enterFullscreen(fullscreenElement);
        }
    }

    setCurrentTimeToEdge = (): void =>
        void this.controllerLivestreamInstance?.setCurrentTimeToEdge();

    get bufferedTimeRanges(): BufferRange[] {
        if (!this.videoElement) {
            return [];
        }

        const {buffered} = this.videoElement || {};
        const bufferedLength = get(buffered, 'length');
        const ranges = [] as BufferRange[];

        if (bufferedLength) {
            for (let range = 0; range < bufferedLength; range++) {
                try {
                    ranges.push({
                        start: buffered.start(range),
                        end: buffered.end(range),
                    });
                } catch (e) {} // eslint-disable-line no-empty
            }
        }

        return ranges;
    }

    disableTextTrack = (): void =>
        void this.controllerCaptionsInstance?.disableTextTrack();

    set textTrack(index: number) {
        if (!this.controllerCaptionsInstance) {
            return;
        }

        this.controllerCaptionsInstance.textTrack = index;
    }

    get textTracksList(): TextTracks {
        return this.controllerCaptionsInstance?.textTracksList;
    }

    get shouldDisableCueElement(): boolean | undefined {
        return this.controllerCaptionsInstance?.shouldDisableCueElement;
    }

    get currentTextTrackIndex(): number | undefined {
        return this.controllerCaptionsInstance?.currentTextTrackIndex;
    }

    get bitrateLevels(): BaseBitrateInfo[] | undefined {
        return this.controllerCustomBitrateInstance?.bitrateLevels;
    }

    get bitrateCurrentIndex(): number | undefined {
        return this.controllerCustomBitrateInstance?.bitrateCurrentIndex;
    }

    get bitrateNextIndex(): number {
        return this.controllerCustomBitrateInstance?.bitrateNextIndex ?? NaN;
    }

    set bitrateNextIndex(requestedIndex: number) {
        if (!this.controllerCustomBitrateInstance) {
            return;
        }

        this.controllerCustomBitrateInstance.bitrateNextIndex = requestedIndex;
    }

    get bitrateIsAuto(): boolean | undefined {
        return this.controllerCustomBitrateInstance?.bitrateIsAuto;
    }

    bitrateGetUserPreferredQuality: () => PlayerQuality | undefined = () =>
        this.controllerCustomBitrateInstance?.bitrateGetUserPreferredQuality();

    get bitrateCurrentQuality(): PlayerQualityLevel | undefined {
        return this.controllerCustomBitrateInstance?.bitrateCurrentQuality;
    }

    bitrateSwitchToAuto = (): void =>
        void this.controllerCustomBitrateInstance?.bitrateSwitchToAuto();

    setBitrateToAuto = (): void =>
        void this.controllerCustomBitrateInstance?.setBitrateToAuto();

    setMaxBitrate = ({
        selectedHeight,
        quality,
    }: {
        selectedHeight: number;
        quality?: PlayerQualityLevel;
    }): void =>
        void this.controllerCustomBitrateInstance?.setMaxBitrate({
            selectedHeight,
            quality,
        });

    saveUserPreferredBitrate = ({
        selectedHeight,
        quality,
    }: {
        selectedHeight: number;
        quality?: PlayerQualityLevel;
    }): void => {
        this.controllerCustomBitrateInstance?.bitrateDeleteUserPreferred();

        this.controllerCustomBitrateInstance?.bitrateSaveUserPreferredQuality(
            quality,
            selectedHeight
        );
    };

    get audioTracks(): AudioTrack[] | undefined {
        return this.controllerAudioInstance?.audioTracks;
    }

    get currentAudioTrackIndex(): number {
        return this.controllerAudioInstance?.currentTrackIndex ?? NaN;
    }

    set currentAudioTrackIndex(index: number) {
        if (!this.controllerAudioInstance) {
            return;
        }

        this.controllerAudioInstance.currentAudioTrackIndex = index;
    }

    get capLevelToPlayerSize(): boolean {
        // Returns the controllerCustomBitrateInstance to determine if we're capping.
        // If that's not present yet, we default to handing back the 'options' understanding of it (which can get overriden later)
        if (this.controllerCustomBitrateInstance) {
            return this.controllerCustomBitrateInstance.capLevelToPlayerSize;
        } else {
            return this.options.capLevelToPlayerSize ?? false;
        }
    }

    set capLevelToPlayerSize(shouldCap: boolean) {
        // If the controller for controlling bitrate is not present yet, player tech has us covered to
        // hand in next time this shouldCap value on initial launch.  This means when switching techs, or tech is currently empty,
        // we'll know to set it on boot vs after initialisation when a source comes in.
        if (this.controllerCustomBitrateInstance) {
            this.controllerCustomBitrateInstance.capLevelToPlayerSize =
                shouldCap;
        }
    }

    get supportsAirPlay(): boolean {
        return supportsAirPlay();
    }

    showAirPlayTargetPicker(): void {
        this.videoElement?.webkitShowPlaybackTargetPicker();
    }

    get supportsPopOutPlayer(): boolean {
        if (!this.videoElement) {
            return false;
        }

        return supportsPopOutPlayer(this.videoElement);
    }

    get isPopOutPlayer(): boolean {
        if (!this.videoElement) {
            return false;
        }

        return getIsInPopOutMode(this.videoElement);
    }

    set isPopOutPlayer(shouldPopOut: boolean) {
        if (!this.videoElement) {
            return;
        }

        setIsInPopOutMode(this.videoElement, shouldPopOut);
    }

    toggleIsPopOutPlayer(): void {
        if (this.supportsPopOutPlayer) {
            this.isPopOutPlayer = !this.isPopOutPlayer;
        } else {
            console.warn(
                "PlayerTech: Unable to change popOutPlayer mode, since it's not supported."
            );
        }
    }

    get activeKeySystemName(): string | null {
        return this.controllerEmeInstance?.activeKeySystemName || null;
    }

    get isPlaying(): boolean {
        return !this.videoElement?.paused;
    }
}

export default PlaybackNative;
