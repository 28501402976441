import bacon from 'baconjs';
// Important! Keep this before npaw-plugin-nwf
// eslint-disable-next-line import/no-unassigned-import
import './npaw-helper';
import NpawPlugin from 'npaw-plugin-nwf';

import {DEFAULT_VIMOND_ENV} from '../constants';
import {filterEventOn} from '../video-normalised-events';

import getYouboraOptions from './get-youbora-options';
import createVideoFsHtml5Adapter from './create-video-fs-html5-adapter';
import registerVideoHandlers from './register-video-handlers';

export default function getYouboraTracking({
    sport = 'afl',
    videoNormalisedEvents = new bacon.Bus(),
    videoDetails = {},
    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const youboraPlugin$ = videoNormalisedEvents
        .filter(filterEventOn(['sourceupdate']))
        .flatMapLatest(({playerTech}) => {
            // Only track if we have a source.
            // Mounted _may_ have an initial source, but it's probably very unlikely (whole get the key thing)
            if (!playerTech.currentSource) {
                return bacon.never();
            }

            return new NpawPlugin(
                vimondEnv === 'prod' ? 'foxsportsaustralia' : 'foxsportsaustraliadev',
                // Disable the CDN balancer because it modifies `XMLHttpRequest`.
                // Important: Do not change this until you have verified that it no longer modifies core browser APIs!
                {
                    components: {balancer: false},
                }
            );
        });

    const youboraTracking$ = videoNormalisedEvents
        .filter(filterEventOn(['firstplay']))
        .combine(youboraPlugin$, (event, youboraPlugin) => ({event, youboraPlugin}))
        .doAction(({event: {playerTech}, youboraPlugin}) => {
            youboraPlugin.registerAdapterFromClass(
                playerTech.videoElement,
                createVideoFsHtml5Adapter(playerTech)
            );
            youboraPlugin.setAnalyticsOptions(
                getYouboraOptions(videoDetails, sport, playerTech.currentSource)
            );
            youboraPlugin.getAdapter().fireInit();
        })
        .flatMapLatest(({event: {playerTech}, youboraPlugin}) => bacon.fromBinder((sink) => {
            const unsubscribe = registerVideoHandlers(playerTech, youboraPlugin);

            sink(youboraPlugin);

            return () => {
                unsubscribe();
                youboraPlugin.getAdapter().fireStop();
                youboraPlugin.removeAdapter();
            };
        }));

    return youboraTracking$.toProperty(null);
}

