import React from 'react';
import ReactDOM from 'react-dom';
import bacon from 'baconjs';

import {initialLaterOr} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {handleStreamForIsoRender, renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {asArray} from '@fsa/fs-commons/lib/utils/normalise-arg';

import getMatchVideoData from '../streams/match-video';
import MatchVideoComponent from '../components/match-video';
import {LIVE_VIDEO_POLL_FREQUENCY} from '../utils/constants';

import HawkPlayer from './hawk-player';

const ISO_ERROR_EXPIRES_IN = 10;
const ISO_SUCCESS_EXPIRES_IN = 5 * 60;

function MatchVideo(element, settings) {
    this.element  = element;
    this.settings = settings;

    this.config = {
        assetTypes: asArray(this.settings.assetTypes, null),
        fixtureId: this.settings.fixtureId,
        sport: this.settings.sport,
        supportUrl: this.settings.supportUrl,

        vimondEnv: this.settings.vimondEnv || 'prod'
    };
}

MatchVideo.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

MatchVideo.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Match Video',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

MatchVideo.prototype.initComponentStream = function (initialData = false) {
    const data = this.getData(initialData);
    const reactElement = data.map((data) => <MatchVideoComponent {...data.view} />);
    const iso = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

MatchVideo.prototype.getData = function (initialData) {
    const {
        assetTypes,
        fixtureId,
        sport,
        supportUrl,

        vimondEnv
    } = this.config;

    const matchDetailsInitial = initialLaterOr(
        initialData && initialData.matchDetailsInitial,
        getMatchVideoData({
            assetTypes,
            fixtureId,
            sport,

            vimondEnv
        })
    );

    const matchDetailsPolling = matchDetailsInitial
        .flatMapLatest((matchDetails) => {
            if (matchDetails.hasVideo) {
                return matchDetails;
            }

            const matchVideoData = getMatchVideoData({
                assetTypes,
                fixtureId,
                freq: LIVE_VIDEO_POLL_FREQUENCY,
                sport,

                vimondEnv
            });

            const matchVideoStream = bacon.update(
                matchDetails,
                [matchVideoData], (oldMatchVideoData, newMatchVideoData) => {
                    if (oldMatchVideoData.hasVideo) {
                        // we need to ignore the first true, so that takeWhile would resolve with live data
                        newMatchVideoData.hasLiveVideo = true;
                    }

                    return newMatchVideoData;
                }
            )
                .takeWhile((matchDetails) => !matchDetails.hasLiveVideo);

            return bacon
                .later(matchDetails.timeToNextPoll, null)
                .skip(1)
                .concat(
                    matchVideoStream
                )
                .startWith(matchDetails)
                .map((matchDetails) =>  matchDetails);
        });

    const videoTypeBus = new bacon.Bus();

    const shouldRenderStartCard = bacon.update(
        true,
        [videoTypeBus], (_, type) => !type
    );

    const handleVideoClosed = () => {
        videoTypeBus.push(null);
    };

    const videoPlayer = bacon.combineWith(matchDetailsPolling, videoTypeBus.toProperty(null),
        (matchDetails, type) => HawkPlayer(null, type ? {
            autoplay: true,
            liveFromStart: type === 'start',
            onClose: handleVideoClosed,
            oneClickPlayer: true,
            sport,
            supportUrl,
            videoDetails: matchDetails.video || {},
            vimondEnv
        } : {})
    )
        .flatMapLatest((player) => player.initComponentStream())
        .map('.reactElement');

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            hasVideo: matchDetailsPolling.map('.hasVideo'),
            shouldRenderLive: matchDetailsPolling.map('.shouldRenderLive'),
            shouldRenderStartCard,
            awayTeam: matchDetailsPolling.map('.awayTeam'),
            homeTeam: matchDetailsPolling.map('.homeTeam'),
            videoPlayer,
            onClickPlay: (type) => { videoTypeBus.push(type); }
        }),
        iso: bacon.combineTemplate({
            matchDetailsInitial
        })
    });
};

MatchVideo.prototype.render = function (data) {
    if (this.element) {
        ReactDOM.render(
            <MatchVideoComponent {...data.view} />,
            this.element
        );
    } else {
        return renderToHtml(
            <MatchVideoComponent {...data.view} />,
            'hawkwidgets-match-video',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

MatchVideo.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element) {
            ReactDOM.unmountComponentAtNode(this.element);
        }
    } catch (e) {} // eslint-disable-line no-empty
};

MatchVideo.prototype.pageBoot = function () {
    pageBoot(MatchVideo, 'hawkwidgets-match-video');
};

pageBoot(MatchVideo, 'hawkwidgets-match-video');

export default function (element, settings) {
    return new MatchVideo(element, settings);
}
